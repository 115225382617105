/* Coin interaction effect */
.claim-coins {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: pulsate 1.5s infinite; /* Pulsating animation */
  }
  
  .claim-coins:hover {
    transform: scale(1.1); /* Slightly enlarge the coin on hover */
    box-shadow: 0 0 15px 5px rgba(255, 223, 0, 0.7); /* Yellow glow effect */
  }
  
  /* Pulsating glow animation */
  @keyframes pulsate {
    0% {
      box-shadow: 0 0 5px rgba(156, 112, 9, 0.394);
    }
    50% {
      box-shadow: 0 0 15px rgb(230, 132, 5);
    }
    100% {
      box-shadow: 0 0 5px rgba(234,105 , 24, 0.879);
    }
  }